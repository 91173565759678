<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title">Koin List</div>
        <div class="flex flex-row mb-2 pr-2 float-right">
          <b-input style="width: 200px" size="sm" type="text" v-model="user.search_keyword" placeholder="Input search keyword" class="mr-2"/>
          <el-select size="small" v-model="user.search_by" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in user.search_options" :key="index" :value="item.value" :label="item.text"/>
          </el-select>
          <el-select size="small" v-model="user.searchType" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in user.searchUserTypeOptions" :key="index" :value="item.value" :label="item.text"/>
          </el-select>
          <el-button type="primary" @click="searchHandlerGami" size="small"><font-awesome-icon icon="search"/> Search</el-button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="20%" @click="toggleSort('full_name')" style="cursor: pointer"> Name
                 <span v-if="isSorted && sortKey === 'full_name'">
                  {{ sortOrder === 1 ? '🔼' : '🔽' }}
                </span>
              </th>
              <th width="20%">Email</th>
              <th width="10%">Phone Number</th>
              <th>Company Name</th>
              <th>Status </th>
            </tr>
          </thead>
          <tbody v-loading="loading.table">
            <tr v-for="item in sortedList" :key="item.id">
              <td>{{ item.full_name }}</td>
              <td>{{ item.email }}</td>
              <td class="text-nowrap text-muted">{{ item.phone_number }}</td>
              <td>{{ item.company_name || '-' }}</td>
              <td>
                <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                  <a class="dropdown-item btn-sm cursor-pointer" @click="detailCoinHistory(item)"><font-awesome-icon icon="ticket-alt" class="text-primary"/>&nbsp; Detail</a>
                </div>
              </td>
             </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="size"
            :limit="5"
            :total-rows="total_rows"
            v-model="page"
          />
        </div>
      </div>
     </div>
    <div>
    </div>
    <b-modal size="lg" v-model="showHistoryCoin" :title="'Detail koin #' + selected_transaction.owned_by" hide-footer>
      <el-descriptions class="margin-top mb-3" title="Order Information" :column="2" border>   
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            Name
          </template>
          {{ selected_transaction.full_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-document"></i>
            Email
          </template>
          {{ selected_transaction.email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            Company Name
          </template>
          {{ selected_transaction.company_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-phone"></i>
            Phone Number
          </template>
          {{ selected_transaction.phone_number }}
        </el-descriptions-item>    
      </el-descriptions>
      <div>
        <div class="card" v-if="history_coin.length > 0">
          <div class="card-header border-0 justify-content-between">
            <div class="card-title">History Koin</div>
          </div>
          <div class="card-body scrollable-card-body">
            <el-timeline>
              <el-timeline-item v-for="item in history_coin" :timestamp="formatDate(item.timestamp)"
                placement="top"
                color="#ff8f15"
                type="#ff8f15"
                class="text-primary"
                :key="item.title">
                <el-card class="custom-card">
                  <p> {{ item.title }}  <strong class="font-weight-bold text-bold" style="text-bold font-weight: bold;">{{ (item.amount) }} Koin </strong> at  <span>{{ formatDate(item.timestamp) }}</span> </p>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
        <div class="card" v-else>
          <div class="card-header border-0 justify-content-between">
            <div class="card-title text-danger"> No history available for the selected koin. </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import { GET_COIN_HISTORY } from '@/store/modules/coin';
import emptyStateImage from '@/assets/images/empty-state.png';
import Pagination from '../components/Pagination.vue';
import { GET_APPUSERS } from '@/store/modules/app_user';
export default {
  components: { Pagination },
  name: 'Koin Balance and History',
  metaInfo: {
    title: 'Koin Balance',
  },
  data() {
    return {
      user: {
        search_by: 'full_name',
        search_keyword: '',
        searchType: 1,
        searchUserTypeOptions: [
          { value: 1, text: 'Private' },
          { value: 0, text: 'Public' },
        ],
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
          { value: 'phone_number', text: 'Phone Number' },
        ],
      },
      history_coin: [],
      isLoadingHistory: false,
      emptyStateImage,
      showHistoryCoin: false,
      showSearch: true,
      search_options: [
        { value: 'full_name', text: 'Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
      ],
      per_page: 20,
      size: 0,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loading_detail: false,
      loader: null,
      user_email: null,
      search_keyword: '',
      search_by: '',
      selected_transaction: {
        user: {},
      },
      sortKey: 'full_name', 
      sortOrder: 1 ,
      isSorted: true,
      list_user: [],
      selected_item: {},
      loading: {
        summary_vendor: false,
      },
      search: {
        date: [
          moment().subtract(30, 'day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        search_by: 'full_name',
        search_keyword: '',
        start_date: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        status_filter: '',
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list_user.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    sortedList() {
      let sorted = this.list_user.slice();
      if (this.isSorted && this.sortKey) {
        sorted.sort((a, b) => {
          let result = 0;
          if (a[this.sortKey] && b[this.sortKey]) {
            const valA = a[this.sortKey];
            const valB = b[this.sortKey];
            if (typeof valA === 'string' && typeof valB === 'string') {
              result = valA.localeCompare(valB);
            } else {
              result = valA < valB ? -1 : valA > valB ? 1 : 0; 
            }
          }
          return result * this.sortOrder;
        });
      }
      return sorted;
    }
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getListCoin();
    loader.hide();
  },
  methods: {
    async detailCoinHistory(item) {
      this.selected_transaction = item;
      this.showHistoryCoin = true;
      this.loading = true;
      try {
        const response = await this.$store.dispatch(GET_COIN_HISTORY, { owned_by: this.selected_transaction.owned_by, page: this.page });
        if (response.data && response.data.rows && response.data.rows.length > 0) {
          const historyData = response.data.rows.map(entry => ({
            title: entry.title,
            description: entry.description,
            type: entry.type,
            amount: entry.amount,
            timestamp: entry.timestamp
          }));
          this.history_coin = historyData; 
          if (this.history_coin.length === 0) {
              this.$message.info('No history available for the selected koin.');
          }
        } else {
            this.history_coin = [];
            this.$message.info('No history available for the selected koin.');
        }
      } catch (error) {
          this.$message.error('Error fetching koin history');
      } finally {
          this.loading = false;
      }
    },
    capitalizeFirst(value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getListCoin();
    },
    searchHandler() {
      this.page = 1;
      this.search.action = '';
      this.search.start_date = moment(this.search.date[0]).format('YYYY-MM-DD');
      this.search.end_date = moment(this.search.date[1]).format('YYYY-MM-DD');
      this.getListCoin();
    },
    searchHandlerGami() {
      this.search_by = this.user.search_by;
      this.search_keyword = this.user.search_keyword;
      this.searchType = this.user.searchType
      this.getListCoin();
    },
    viewHistoryCoin(item) {
      this.selected_transaction = item;
      this.selected_item = item;
      this.showHistoryCoin = true;
    },
    async getListCoin() {
      this.isLoading = true;
      await this.$store.dispatch(GET_APPUSERS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        is_private: this.user.searchType,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.app_users;
      this.total_rows = count || 0;
      this.list_user = rows.map((v) => {
        v.birth_place_date = (v.birth_place && v.birthday) ? [v.birth_place, moment(v.birthday).format('DD MMM YYYY')].join(', ') : '-';
        return v;
      });
      this.isLoading = false;
    },
    formatDate(timestamp) {
      return moment(timestamp).format('D MMMM YYYY, HH:mm A');
    },
    formatNumber(value) {
      return new Intl.NumberFormat('id-ID').format(value);
    },
    toggleSort(key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1; 
      } else {
        this.sortKey = key;  
        this.sortOrder = 1;    
      }
      this.isSorted = true;   
    },
    resetSort() {
      this.isSorted = false;  
      this.sortKey = null;
      this.sortOrder = 1;
    }
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}

.scrollable-card-body {
  max-height: 400px; 
  overflow-y: auto;
}

.el-timeline-item__timestamp {
  color: orange;
}

.custom-card {
  width: 500px;  
  height: auto; 
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

</style>
